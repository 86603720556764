import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, Fragment, useContext, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  Row,
} from "reactstrap";
import AppContext, { ActivityContext } from "../../context/Context";
import { getPaginationArray } from "../../helpers/utils";
import FalconCardHeader from "../common/FalconCardHeader";

import NewTag from "../utilities/NewTag";

const shippingFormatter = (employee) => {
  if (!(employee === null)) {
    return <Fragment>{employee.name}</Fragment>;
  } else {
    return (
      <Fragment>
        <p className="p-0 m-0" style={{ color: "#5e6e82aa" }}>
          No disponible
        </p>
      </Fragment>
    );
  }
};

const commentFormatter = (comment) => {
  if (comment) {
    return <Fragment>{comment}</Fragment>;
  } else {
    return (
      <Fragment>
        <p className="p-0 m-0" style={{ color: "#5e6e82aa" }}>
          N/A
        </p>
      </Fragment>
    );
  }
};

const folioFormatter = (dat) => {
  var folio;
  try {
    folio = dat.slice(-5);
  } catch {
    folio = " ";
  }
  return (
    <Fragment>
      <span translate="no">{folio}</span>
    </Fragment>
  );
};

const amountFormatter = (amount) => {
  return (
    <Fragment>
      {"$"}
      {amount}
    </Fragment>
  );
};

const dateFormatter = (dataField, { date }) => (
  <Fragment>
    <strong>{new Date(date.seconds * 1000).toLocaleString()}</strong>
  </Fragment>
);

const validFormatter = (dataField, { validVisit }) => (
  <Fragment>
    {validVisit ? (
      <FontAwesomeIcon icon="check-circle" className="text-success" style={{ fontSize: "1rem" }} />
    ) : (
      <FontAwesomeIcon icon="times-circle" className="text-danger" style={{ fontSize: "1rem" }} />
    )}
  </Fragment>
);

const clientFormatter = (dataField, { client, newClient }, row) => {
  if (newClient) {
    return (
      <Fragment>
        <NewTag />
        {client.name ? client.name : <p className="text-500 m-0 p-0">No Disponible</p>}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {client.name ? client.name : <p className="text-500 m-0 p-0">No Disponible</p>}
      </Fragment>
    );
  }
};

// const actionFormatter = (dataField, { id }: row) => (
//   <UncontrolledDropdown>
//     <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
//       <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
//     </DropdownToggle>
//     <DropdownMenu right className="border py-2">
//       <DropdownItem onClick={() => console.log('Completed: ', id)}>Completed</DropdownItem>
//       <DropdownItem onClick={() => console.log('Processing: ', id)}>Processing</DropdownItem>
//       <DropdownItem onClick={() => console.log('On hold: ', id)}>On hold</DropdownItem>
//       <DropdownItem onClick={() => console.log('Pending: ', id)}>Pending</DropdownItem>
//       <DropdownItem divider />
//       <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
//         Delete
//       </DropdownItem>
//     </DropdownMenu>
//   </UncontrolledDropdown>
// );

const sortEmployees = (a, b) => {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const onTableChange = (type, newState) => {};

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => {}}
      ref={(input) => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const selectRow = (onSelect) => ({
  mode: "checkbox",
  classes: "py-2 align-middle",
  clickToSelect: false,
  selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
  selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
  onSelect: onSelect,
  onSelectAll: onSelect,
});

const Orders = () => {
  const { data, setData, fullDataSet, employeeInfoArray, selectedLocation } =
    useContext(ActivityContext);
  const { locations } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);

  useEffect(() => {
    setPaginationPage(1);
  }, [selectedLocation]);

  const locationFormatter = (dataField, { location }) => {
    let locationName = "";

    for (const locDoc of locations) {
      if (location === locDoc.id) {
        locationName = locDoc.name;
        break;
      }
    }

    return (
      <Fragment>
        {location ? (
          <p className="m-0">{locationName}</p>
        ) : (
          <p className="text-500 m-0 p-0">No Disponible</p>
        )}
      </Fragment>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: "Fecha",
      classes: "py-2 align-middle",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "validVisit",
      text: "Válida",
      classes: "py-2 align-middle",
      formatter: validFormatter,
      sort: true,
    },
    {
      dataField: "client.name",
      text: "Cliente",
      classes: "py-2 align-left",
      formatter: clientFormatter,
      sort: true,
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "employee",
      text: "Empleado",
      classes: "py-2 align-middle",
      sort: true,
      sortFunc: sortEmployees,
      formatter: shippingFormatter,
    },
    {
      dataField: "uid",
      text: "Folio",
      classes: "py-2 align-middle",
      formatter: folioFormatter,
      sort: true,
    },
    {
      dataField: "amount",
      text: "Consumo",
      classes: "py-2 align-left",
      formatter: amountFormatter,
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "comment",
      text: "Comentario",
      classes: "py-2 align-left",
      formatter: commentFormatter,
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "sucursal",
      text: "Sucursal",
      classes: "py-2 align-right pr-3",
      formatter: locationFormatter,
      sort: true,
      align: "right",
      headerAlign: "right",
    },
  ];

  const options = {
    custom: true,
    page: paginationPage,
    sizePerPage: 10,
    totalSize: data.length,
  };

  function filterDataSetByEmployee(uid) {
    setPaginationPage(1);
    if (uid !== "") {
      setData(fullDataSet.filter((rewardLog) => rewardLog.employee.uid === uid));
    } else {
      setData(fullDataSet);
    }
  }

  let table = createRef();

  const [isSelected, setIsSelected] = useState(false);
  const handleNextPage =
    ({ page, onPageChange }) =>
    () => {
      setPaginationPage(page + 1);
      onPageChange(page + 1);
    };

  const handlePrevPage =
    ({ page, onPageChange }) =>
    () => {
      setPaginationPage(page - 1);
      onPageChange(page - 1);
    };

  const onSelect = () => {
    setImmediate(() => {
      setIsSelected(!!table.current.selectionContext.selected.length);
    });
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Actividad Reciente" light={false}>
        {isSelected ? (
          <InputGroup size="sm" className="input-group input-group-sm">
            <CustomInput type="select" id="bulk-select">
              <option>Bulk actions</option>
              <option value="Refund">Refund</option>
              <option value="Delete">Delete</option>
              <option value="Archive">Archive</option>
            </CustomInput>
            <Button color="falcon-default" size="sm" className="ml-2">
              Apply
            </Button>
          </InputGroup>
        ) : (
          <Fragment>
            <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
              <DropdownToggle caret className="btn btn-falcon-default falcon-default">
                Filtrar
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    filterDataSetByEmployee("");
                  }}
                >
                  Sin Filtro
                </DropdownItem>
                <DropdownItem divider />
                {employeeInfoArray.map((employee) => {
                  return (
                    <DropdownItem
                      onClick={() => {
                        filterDataSetByEmployee(employee.uid);
                      }}
                    >
                      {employee.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </Fragment>
        )}
      </FalconCardHeader>
      <CardBody className="p-0">
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            const lastIndex = paginationProps.page * paginationProps.sizePerPage;
            return (
              <Fragment>
                <div className="table-responsive">
                  <BootstrapTable
                    ref={table}
                    bootstrap4
                    keyField="id"
                    data={data}
                    onTableChange={onTableChange}
                    columns={columns}
                    // selectRow={selectRow(onSelect)}
                    bordered={false}
                    classes="table-dashboard table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger"
                    headerClasses="bg-200 text-900"
                    {...paginationTableProps}
                  />
                </div>
                <Row noGutters className="px-1 py-3 flex-center">
                  <Col xs="auto">
                    <Button
                      color="falcon-default"
                      size="sm"
                      onClick={handlePrevPage(paginationProps)}
                      disabled={paginationProps.page === 1}
                    >
                      <FontAwesomeIcon icon="chevron-left" />
                    </Button>
                    {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(
                      (pageNo) => (
                        <Button
                          color={
                            paginationProps.page === pageNo ? "falcon-primary" : "falcon-default"
                          }
                          size="sm"
                          className="ml-2"
                          onClick={() => paginationProps.onPageChange(pageNo)}
                          key={pageNo}
                        >
                          {pageNo}
                        </Button>
                      )
                    )}
                    <Button
                      color="falcon-default"
                      size="sm"
                      className="ml-2"
                      onClick={handleNextPage(paginationProps)}
                      disabled={lastIndex >= paginationProps.totalSize}
                    >
                      <FontAwesomeIcon icon="chevron-right" />
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default Orders;
