import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, Fragment, useContext, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Badge from "reactstrap/es/Badge";
import { default as AppContext, default as UserDataContext } from "../../context/Context";
import { getPaginationArray } from "../../helpers/utils";
import FalconCardHeader from "../common/FalconCardHeader";

// import firebase from "firebase/compat/app";
// import 'firebase/compat/firestore';

const orderFormatter = (dataField, { id }) => (
  <Fragment>
    <Link to="/">
      <strong>ID: {id}</strong>
    </Link>
  </Fragment>
);

const shippingFormatter = (employee) => {
  if (!(employee === null) && !(employee === undefined)) {
    return <Fragment>{employee.name}</Fragment>;
  } else {
    return (
      <Fragment>
        <p className="p-0 m-0" style={{ color: "#5e6e82aa" }}>
          No disponible
        </p>
      </Fragment>
    );
  }
};

const badgeFormatter = (status) => {
  let color = "";
  let icon = "";
  let text = "";
  switch (status) {
    case "success":
      color = "success";
      icon = "check";
      text = "Completed";
      break;
    case "hold":
      color = "secondary";
      icon = "ban";
      text = "On hold";
      break;
    case "processing":
      color = "primary";
      icon = "redo";
      text = "Processing";
      break;
    case "pending":
      color = "warning";
      icon = "stream";
      text = "Pending";
      break;
    default:
      color = "warning";
      icon = "stream";
      text = "Pending";
  }

  return (
    <Badge color={`soft-${color}`} className="rounded-capsule fs--1 d-block">
      {text}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const dateFormatter = (dataField, { time }) => (
  <Fragment>
    <strong>{new Date(time.seconds * 1000).toLocaleString()}</strong>
  </Fragment>
);

const clientFormatter = (dataField, { client, clientid }) => {
  if (client === undefined) {
    return (
      <Fragment>
        <strong>{clientid}</strong>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <strong>{client.name}</strong>
      </Fragment>
    );
  }
};

const rewardFormatter = (dataField, { reward }) => {
  if (reward.type === "point-claim") {
    return (
      <Fragment>
        <strong>
          (-{reward.amount}) {reward.description}
        </strong>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <strong>{reward.description}</strong>
      </Fragment>
    );
  }
};

// const actionFormatter = (dataField, { id }) => (
//   // Control your row with this id
//   <UncontrolledDropdown>
//     <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
//       <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
//     </DropdownToggle>
//     <DropdownMenu right className="border py-2">
//       <DropdownItem onClick={() => console.log('Completed: ', id)}>Completed</DropdownItem>
//       <DropdownItem onClick={() => console.log('Processing: ', id)}>Processing</DropdownItem>
//       <DropdownItem onClick={() => console.log('On hold: ', id)}>On hold</DropdownItem>
//       <DropdownItem onClick={() => console.log('Pending: ', id)}>Pending</DropdownItem>
//       <DropdownItem divider />
//       <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
//         Delete
//       </DropdownItem>
//     </DropdownMenu>
//   </UncontrolledDropdown>
// );

const sortEmployees = (a, b, order) => {
  switch (order) {
    case "asc":
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      return 0;

    default:
      if (a.name > b.name) {
        return -1;
      } else if (a.name < b.name) {
        return 1;
      }
      return 0;
  }
};

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => {}}
      ref={(input) => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const selectRow = (onSelect) => ({
  mode: "checkbox",
  classes: "py-2 align-middle",
  clickToSelect: false,
  selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
  selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
  onSelect: onSelect,
  onSelectAll: onSelect,
});

const ClaimsLog = () => {
  const { companyID, locations } = useContext(UserDataContext);
  const [data, setData] = useState([]);
  const { claims } = useContext(AppContext);

  const options = {
    custom: true,
    sizePerPage: 10,
    totalSize: data.length,
  };
  const folioFormatter = (dat) => {
    var folio;
    try {
      folio = dat.slice(-5);
    } catch {
      folio = " ";
    }
    return (
      <Fragment>
        <span translate="no">{folio}</span>
      </Fragment>
    );
  };
  const locationFormatter = (dataField, { location }) => {
    let locationName = "";

    for (const locDoc of locations) {
      if (location === locDoc.id) {
        locationName = locDoc.name;
        break;
      }
    }

    return (
      <Fragment>
        {location ? (
          <p className="m-0">{locationName}</p>
        ) : (
          <p className="text-500 m-0 p-0">No Disponible</p>
        )}
      </Fragment>
    );
  };

  const columns = [
    {
      dataField: "time.seconds",
      text: "Fecha",
      classes: "py-2 align-middle",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "reward.description",
      text: "Recompensa",
      classes: "py-2 align-middle",
      formatter: rewardFormatter,
      sort: true,
    },

    {
      dataField: "employee",
      text: "Empleado",
      classes: "py-2 align-middle",
      formatter: shippingFormatter,
      sortFunc: sortEmployees,
      sort: true,
    },
    {
      dataField: "folio",
      text: "Folio",
      classes: "py-2 align-middle",
      formatter: folioFormatter,
      sort: true,
    },
    {
      dataField: "client.name",
      text: "Cliente",
      classes: "py-2 align-right",
      formatter: clientFormatter,
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "location",
      text: "Sucursal",
      classes: "py-2 align-right pr-3",
      formatter: locationFormatter,
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    // {
    //   dataField: '',
    //   text: '',
    //   classes: 'py-2 align-middle',
    //   formatter: actionFormatter,
    //   align: 'right'
    // }
  ];

  useEffect(() => {
    var today = new Date();
    today.setDate(today.getDate() - 2);
    today.setHours(0, 0, 0, 0);

    var visitDate;
    var rawData = [];
    for (var t = 0; t < claims.length; t++) {
      visitDate = new Date(claims[t].time.seconds * 1000);
      if (visitDate >= today) {
        rawData.push(claims[t]);
      }
    }
    setData(rawData);
  }, [claims]);
  let table = createRef();

  const [isSelected, setIsSelected] = useState(false);
  const handleNextPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page + 1);
    };

  const handlePrevPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page - 1);
    };

  const onSelect = () => {
    setImmediate(() => {
      setIsSelected(!!table.current.selectionContext.selected.length);
    });
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Canjes Recientes" light={false}>
        {/* {isSelected ? (
            <InputGroup size="sm" className="input-group input-group-sm">
              <CustomInput type="select" id="bulk-select">
                <option>Bulk actions</option>
                <option value="Refund">Refund</option>
                <option value="Delete">Delete</option>
                <option value="Archive">Archive</option>
              </CustomInput>
              <Button color="falcon-default" size="sm" className="ml-2">
                Apply
              </Button>
            </InputGroup>
          ) : (
            <Fragment>
              <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
                New
              </ButtonIcon>
              <ButtonIcon icon="filter" transform="shrink-3 down-2" color="falcon-default" size="sm" className="mx-2">
                Filter
              </ButtonIcon>
              <ButtonIcon icon="external-link-alt" transform="shrink-3 down-2" color="falcon-default" size="sm">
                Export
              </ButtonIcon>
            </Fragment>
          )} */}
      </FalconCardHeader>
      <CardBody className="p-0">
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            const lastIndex = paginationProps.page * paginationProps.sizePerPage;

            var maxBatches = Math.ceil(paginationProps.totalSize / 12);

            var batches = [];
            var rep = 0;
            return (
              <Fragment>
                <div className="table-responsive">
                  <BootstrapTable
                    ref={table}
                    bootstrap4
                    keyField="id"
                    data={data}
                    columns={columns}
                    // selectRow={selectRow(onSelect)}
                    bordered={false}
                    classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger"
                    headerClasses="bg-200 text-900"
                    {...paginationTableProps}
                  />
                </div>
                <Row noGutters className="px-1 py-3 flex-center">
                  <Col xs="auto">
                    <Button
                      color="falcon-default"
                      size="sm"
                      onClick={handlePrevPage(paginationProps)}
                      disabled={paginationProps.page === 1}
                    >
                      <FontAwesomeIcon icon="chevron-left" />
                    </Button>

                    {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(
                      (pageNo) => (
                        <Button
                          color={
                            paginationProps.page === pageNo ? "falcon-primary" : "falcon-default"
                          }
                          size="sm"
                          className="ml-2"
                          onClick={() => paginationProps.onPageChange(pageNo)}
                          key={pageNo}
                        >
                          {pageNo}
                        </Button>
                      )
                    )}

                    <Button
                      color="falcon-default"
                      size="sm"
                      className="ml-2"
                      onClick={handleNextPage(paginationProps)}
                      disabled={lastIndex >= paginationProps.totalSize}
                      // disabled={paginationProps.page >= 12}
                    >
                      <FontAwesomeIcon icon="chevron-right" />
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

export default ClaimsLog;
